import Base from './Base';
import { transformRequest } from '../utils/TextFormat';

export default class Anime extends Base {
  constructor() {
    super();
  }

  // Mark Mark投稿
  saveMark(animeSeriesId, animeSeasonId, params) {
    return this.client.post(`/animes/series/${animeSeriesId}/seasons/${animeSeasonId}/marks`, params, {
      transformRequest: [transformRequest]
    });
  }

  // Mark Mark詳細取得
  fetchMark(animeSeriesId, animeSeasonId, markId, params) {
    return this.client.get(`/animes/series/${animeSeriesId}/seasons/${animeSeasonId}/marks/${markId}`, {
      params: params
    });
  }

  // Mark Mark更新
  updateMark(animeSeriesId, animeSeasonId, markId, params) {
    return this.client.patch(`/animes/series/${animeSeriesId}/seasons/${animeSeasonId}/marks/${markId}`, params, {
      transformRequest: [transformRequest]
    });
  }

  // Mark Mark削除
  deleteMark(animeSeriesId, animeSeasonId, markId, params) {
    return this.client.delete(`/animes/series/${animeSeriesId}/seasons/${animeSeasonId}/marks/${markId}`, params);
  }

  // Clip Clipする
  saveClip(animeSeriesId, animeSeasonId, params) {
    return this.client.post(`/animes/series/${animeSeriesId}/seasons/${animeSeasonId}/clips`, params);
  }

  // Clip Clip削除
  deleteClip(animeSeriesId, animeSeasonId, clipId, params) {
    return this.client.delete(`/animes/series/${animeSeriesId}/seasons/${animeSeasonId}/clips/${clipId}`, params);
  }

  // MarkComment コメントを取得
  fetchMarkComment(animeSeriesId, animeSeasonId, markId, params) {
    return this.client.get(`/animes/series/${animeSeriesId}/seasons/${animeSeasonId}/marks/${markId}/comments`, {
      params: params
    });
  }

  // ユーザ Markにいいね！したユーザー一覧
  fetchMarkLikeUsers(animeSeriesId, animeSeasonId, markId, params) {
    return this.client.get(`/animes/series/${animeSeriesId}/seasons/${animeSeasonId}/marks/${markId}/likes/users`, {
      params: params
    });
  }
}
