import { createApp } from 'vue';
import OnlineMovieAllowedEnv from './../modules/OnlineMovieAllowedEnv';
import OnlineMovieItem from '../components/OnlineMovieItem';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-online-movie')) {
    const app = createApp({
      mixins: [OnlineMovieAllowedEnv],

      components: {
        OnlineMovieItem
      }
    });

    app.use(bugsnagVue).mount('#js-online-movie');
  }
});
