import { createApp } from 'vue';
import eventHub from '../../common/modules/EventHub';
import getCurrentPosition from './../../common/modules/GetCurrentPosition';
import { useGetStartDate } from '../../composables/useGetStartDate';

import MovieScheduleSelectPrefecture from './../components/MovieScheduleSelectPrefecture';
import MovieScheduleSelectArea from './../components/MovieScheduleSelectArea';
import MovieScheduleTogglePlace from './../components/MovieScheduleTogglePlace';
import MovieScheduleDateList from './../components/MovieScheduleDateList';
import MovieScheduleAreas from './../components/MovieScheduleAreas';

import PiaAreaAPI from './../../api_clients/PiaArea';
import errorNotification from './../../utils/ErrorNotification';
import {
  DEFAULT_SELECT_AREA_ID,
  DEFAULT_SELECT_AREA_LABEL,
  TOGGLE_MODE_LOCATION,
  TOGGLE_MODE_PREFECTURE,
  OFFSET_ADDITION,
  NOT_SUPPORT_GEO_LOCATION_ERROR_MESSAGE
} from '../../constants/MovieSchedule';
import { bugsnagVue } from '../../utils/BugsnagInit';

const piaAreaAPI = new PiaAreaAPI();

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-movie-schedule'), (movieSchedule) => {
    const app = createApp({
      setup() {
        return {
          ...useGetStartDate()
        };
      },

      data: function () {
        return {
          movieId: '',
          scheduleDate: '',
          theaterTotalCount: 0,
          areas: [],
          nextPage: false,
          offset: 0,
          fetchAreasTimer: null,
          hasAreas: false,
          isAreaRetrieving: true,
          isShowLoading: false,
          isFinishFirstRetrieving: false,
          toggleMode: TOGGLE_MODE_PREFECTURE,
          selectRegionInfo: {
            regions: [],
            theaterCount: 0,
            isRetrieving: false,
            isShowModal: false,
            selectedId: 13,
            selectedName: '東京都'
          },
          selectAreaInfo: {
            areas: [],
            majorAreas: [],
            theaterCount: 0,
            isRetrieving: false,
            isShowModal: false,
            selectedId: DEFAULT_SELECT_AREA_ID,
            selectedName: DEFAULT_SELECT_AREA_LABEL
          },
          location: {
            latitude: 0,
            longitude: 0,
            isRetrieving: false,
            isUpdated: false,
            isError: false
          }
        };
      },

      created: function () {
        eventHub.$on('MovieSchedulePrefModalButton/on-click-prefecture-name', this.updatePrefecture);
        eventHub.$on('MovieScheduleAreaModalButton/on-click-area-name', this.updateArea);
        eventHub.$on('MovieScheduleDateItem/on-click-schedule-date', this.updateScheduleDate);
        eventHub.$on('MovieScheduleAreas/on-click-more-read', this.fetchMoreAreas);
        eventHub.$on('MovieScheduleSelectPrefecture/on-click-select-prefecture', this.showSelectPrefModal);
        eventHub.$on('MovieScheduleSelectPrefModal/on-click-modal-overlay', this.hideSelectPrefModal);
        eventHub.$on('MovieScheduleSelectArea/on-click-select-area', this.showSelectAreaModal);
        eventHub.$on('MovieScheduleSelectAreaModal/on-click-modal-overlay', this.hideSelectAreaModal);
        eventHub.$on('MovieScheduleTogglePlace/on-click-toggle-place', this.onClickToggleMode);
        eventHub.$on('MovieScheduleTogglePlace/update-location', this.updateLocation);
        eventHub.$on('MovieScheduleTogglePlace/update-retrieving-location', this.onEvUpdateLocationRetrieving);
        eventHub.$on('MovieScheduleTogglePlace/error-get-location', this.errorGetLocation);

        this.setInitialScheduleDate();
      },

      mounted: async function () {
        this.movieId = this.$el.parentElement.dataset.movieId;

        if (this.$el.parentElement.dataset.isLoadedGetLocation === 'true') {
          // 初期表示 現在地モード
          this.toggleMode = TOGGLE_MODE_LOCATION;

          let isGetLocation = false;
          isGetLocation = await this.loadedGetMyLocation();
          if (isGetLocation === true) {
            this.fetchNewAreas();
          }
        } else {
          this.fetchNewAreas();
        }
      },

      beforeUnmount: function () {
        eventHub.$off('MovieSchedulePrefModalButton/on-click-prefecture-name', this.updatePrefecture);
        eventHub.$off('MovieScheduleAreaModalButton/on-click-area-name', this.updateArea);
        eventHub.$off('MovieScheduleDateItem/on-click-schedule-date', this.updateScheduleDate);
        eventHub.$off('MovieScheduleAreas/on-click-more-read', this.fetchMoreAreas);
        eventHub.$off('MovieScheduleSelectPrefecture/on-click-select-prefecture', this.showSelectPrefModal);
        eventHub.$off('MovieScheduleSelectPrefModal/on-click-modal-overlay', this.hideSelectPrefModal);
        eventHub.$off('MovieScheduleSelectArea/on-click-select-area', this.showSelectAreaModal);
        eventHub.$off('MovieScheduleSelectAreaModal/on-click-modal-overlay', this.hideSelectAreaModal);
        eventHub.$off('MovieScheduleTogglePlace/on-click-toggle-place', this.onClickToggleMode);
        eventHub.$off('MovieScheduleTogglePlace/update-location', this.updateLocation);
        eventHub.$off('MovieScheduleTogglePlace/update-retrieving-location', this.onEvUpdateLocationRetrieving);
        eventHub.$off('MovieScheduleTogglePlace/error-get-location', this.errorGetLocation);
      },

      watch: {
        'location.isRetrieving': function () {
          if (this.location.isRetrieving) {
            this.updateIsAreaRetrieving(true);
            this.startFetchAreasTimer(); // 位置取得許可ダイアログ表示中のローディング表示
          } else {
            this.clearFetchAreasTimer();
            this.updateIsAreaRetrieving(false);
          }
        }
      },

      computed: {
        isPrefectureMode: function () {
          return this.toggleMode === TOGGLE_MODE_PREFECTURE;
        },

        isLocationMode: function () {
          return this.toggleMode === TOGGLE_MODE_LOCATION;
        }
      },

      methods: {
        clearAll: function () {
          this.areas = [];
          this.nextPage = false;
          this.offset = 0;
        },

        clearLocation: function () {
          this.location.latitude = 0;
          this.location.longitude = 0;
          this.location.isUpdated = false;
          this.location.isError = false;
        },

        clearSelectAreaInfo: function () {
          this.selectAreaInfo.areas = [];
          this.selectAreaInfo.majorAreas = [];
          this.selectAreaInfo.selectedId = DEFAULT_SELECT_AREA_ID;
          this.selectAreaInfo.selectedName = DEFAULT_SELECT_AREA_LABEL;
        },

        onClickToggleMode: function () {
          this.clearAll();
          this.updateHasAreas();
          this.updateToggleMode();

          if (this.isPrefectureMode) {
            this.clearLocation();
            this.fetchNewAreas();
          }
        },

        updatePrefecture: async function (eventHub) {
          const { id, name, theaterCount } = eventHub.data;
          if (this.selectRegionInfo.selectedId !== id) {
            this.clearSelectAreaInfo();
            this.updatePrefRetrieving(true);
            this.selectRegionInfo.selectedId = id;
            this.selectRegionInfo.selectedName = name;
            this.selectRegionInfo.theaterCount = theaterCount;
            this.hideSelectPrefModal();
            await this.fetchNewAreas();
            this.updatePrefRetrieving(false);
          }
          this.hideSelectPrefModal();
        },

        updateArea: function (eventHub) {
          const { id, name, theaterCount } = eventHub.data;
          if (this.selectAreaInfo.selectedId !== id) {
            this.selectAreaInfo.selectedId = id;
            this.selectAreaInfo.selectedName = name;
            this.selectAreaInfo.theaterCount = theaterCount;
            this.fetchNewAreas();
          }
          this.hideSelectAreaModal();
        },

        updateScheduleDate: function (eventHub) {
          this.scheduleDate = eventHub.data.scheduleDate;
          this.fetchNewAreas();
        },

        fetchAreas: async function () {
          const params = {
            scheduleDate: this.scheduleDate,
            prefectureId: this.selectRegionInfo.selectedId,
            offset: this.offset,
            limit: OFFSET_ADDITION
          };

          if (DEFAULT_SELECT_AREA_ID !== this.selectAreaInfo.selectedId) {
            params.topPriorityAreaId = this.selectAreaInfo.selectedId;
          }

          if (this.location.isUpdated) {
            params.latitude = this.location.latitude;
            params.longitude = this.location.longitude;
          }

          await piaAreaAPI
            .fetchAreas(this.movieId, params)
            .then((response) => {
              this.clearFetchAreasTimer();
              this.appendAreas(response.data.areas);
              this.updateHasAreas();
              this.updateMetaData(response.data);
            })
            .catch((response) => {
              this.clearFetchAreasTimer();
              errorNotification(response);
            });
        },

        fetchRegions: async function () {
          await piaAreaAPI
            .fetchRegions(this.movieId)
            .then((response) => {
              this.updateRegions(response.data.regions);
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        fetchPrefAreas: async function () {
          await piaAreaAPI
            .fetchPrefAreas(this.movieId, this.selectRegionInfo.selectedId)
            .then((response) => {
              this.updatePrefAreas(response.data.areas);
              this.updatePrefMajorAreas(response.data.majorAreas);
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        startFetchAreasTimer: function (ms = 600) {
          this.fetchAreasTimer = setTimeout(() => {
            this.updateShowLoading(true);
          }, ms);
        },

        clearFetchAreasTimer: function () {
          this.updateShowLoading(false);
          clearTimeout(this.fetchAreasTimer);
          this.fetchAreasTimer = null;
        },

        fetchMoreAreas: async function () {
          this.updateIsAreaRetrieving(true);
          this.startFetchAreasTimer(0);
          await this.fetchAreas();
          this.updateIsAreaRetrieving(false);
        },

        fetchNewAreas: async function () {
          this.updateIsAreaRetrieving(true);
          this.clearAll();
          this.updateHasAreas();
          this.startFetchAreasTimer();
          await this.fetchAreas();
          if (!this.isFinishFirstRetrieving) {
            this.isFinishFirstRetrieving = true;
          }
          this.updateIsAreaRetrieving(false);
          this.updateLocationRetrieving(false);
        },

        appendAreas: function (areas) {
          areas.forEach((area) => {
            this.areas.push(area);
          });
        },

        updateHasAreas: function () {
          this.areas.length > 0 ? (this.hasAreas = true) : (this.hasAreas = false);
        },

        updateIsAreaRetrieving: function (bool) {
          this.isAreaRetrieving = bool;
        },

        updatePrefRetrieving: function (bool) {
          this.selectRegionInfo.isRetrieving = bool;
        },

        updatePrefAreaRetrieving: function (bool) {
          this.selectAreaInfo.isRetrieving = bool;
        },

        updateShowLoading: function (bool) {
          this.isShowLoading = bool;
        },

        updateMetaData: function (data) {
          this.nextPage = data.nextPage;
          this.theaterTotalCount = data.theaterTotalCount;
          if (this.isPrefectureMode) {
            this.selectRegionInfo.theaterCount = data.prefectureTheaterCount;
            this.selectAreaInfo.theaterCount = data.areaTheaterCount;
          }
          this.updateOffset();
        },

        updateOffset: function () {
          if (this.nextPage === true) {
            this.offset += OFFSET_ADDITION;
          }
        },

        updateRegions: function (regions) {
          this.selectRegionInfo.regions = regions;
        },

        updatePrefAreas: function (areas) {
          this.selectAreaInfo.areas = areas;
        },

        updatePrefMajorAreas: function (majorAreas) {
          this.selectAreaInfo.majorAreas = majorAreas;
        },

        updateToggleMode: function (isForcePrefMode) {
          if (typeof isForcePrefMode !== 'undefined' && isForcePrefMode === true) {
            this.toggleMode = TOGGLE_MODE_PREFECTURE;
          } else {
            this.isPrefectureMode
              ? (this.toggleMode = TOGGLE_MODE_LOCATION)
              : (this.toggleMode = TOGGLE_MODE_PREFECTURE);
          }
        },

        updateLocation: async function (eventHub) {
          const { latitude, longitude } = eventHub.data;
          this.clearFetchAreasTimer();
          this.updateLocationData(latitude, longitude);
          await this.fetchNewAreas();
          this.updateLocationRetrieving(false);
        },

        updateLocationData: function (latitude, longitude) {
          this.location.isError = false;
          this.location.latitude = latitude;
          this.location.longitude = longitude;
          this.location.isUpdated = true;
        },

        onEvUpdateLocationRetrieving: function (eventHub) {
          this.updateLocationRetrieving(eventHub.data.isRetrieving);
        },

        updateLocationRetrieving: function (isRetrieving) {
          this.location.isRetrieving = isRetrieving;
        },

        setInitialScheduleDate: function () {
          this.scheduleDate = this.getStartDate();
        },

        loadedGetMyLocation: async function () {
          if ('geolocation' in navigator) {
            this.updateLocationRetrieving(true);

            try {
              const position = await getCurrentPosition();
              const { latitude, longitude } = position.coords;
              this.clearFetchAreasTimer();
              this.updateLocationData(latitude, longitude);
              return true;
            } catch (error) {
              errorNotification(error);
              this.errorGetLocation();
              return false;
            }
          } else {
            errorNotification(NOT_SUPPORT_GEO_LOCATION_ERROR_MESSAGE);
            this.errorGetLocation();
            return false;
          }
        },

        showSelectPrefModal: async function () {
          this.updatePrefRetrieving(true);
          if (this.selectRegionInfo.regions.length === 0) {
            await this.fetchRegions();
          }
          this.selectRegionInfo.isShowModal = true;
          this.updatePrefRetrieving(false);
        },

        hideSelectPrefModal: function () {
          this.selectRegionInfo.isShowModal = false;
        },

        showSelectAreaModal: async function () {
          this.updatePrefAreaRetrieving(true);
          if (this.selectAreaInfo.areas.length === 0) {
            await this.fetchPrefAreas();
          }
          this.selectAreaInfo.isShowModal = true;
          this.updatePrefAreaRetrieving(false);
        },

        hideSelectAreaModal: function () {
          this.selectAreaInfo.isShowModal = false;
        },

        errorGetLocation: function () {
          this.location.isError = true;
          // 位置取得エラー文言の表示タイミングを調整
          setTimeout(() => {
            this.updateLocationRetrieving(false);
          }, 500);
        }
      },

      components: {
        MovieScheduleSelectPrefecture,
        MovieScheduleSelectArea,
        MovieScheduleTogglePlace,
        MovieScheduleDateList,
        MovieScheduleAreas
      }
    });

    app.use(bugsnagVue).mount(movieSchedule);
  });
});
