import _isUndefined from 'lodash/isUndefined';
import { createApp } from 'vue';
import * as url from './../../constants/URL';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { PRODUCT_TYPE } from '../../constants/MediaType';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-search-form')) {
    const app = createApp({
      data() {
        return {
          onFocus: false,
          productType: PRODUCT_TYPE.MOVIE,
          selectType: 0, // 0: 映画、ドラマ、アニメ検索, 1: キャスト検索, 2: ユーザー検索
          searchWord: ''
        };
      },

      mounted() {
        this.$nextTick(() => {
          const urlParams = new URLSearchParams(window.location.search);
          this.searchWord = urlParams.get('q') ?? '';
          this.checkSearchLocation();
        });
      },

      computed: {
        isMoviesSearch() {
          return this.selectType === 0;
        },

        isPeopleSearch() {
          return this.selectType === 1;
        },

        isUsersSearch() {
          return this.selectType === 2;
        },

        isUndefinedSearchWord() {
          return _isUndefined(this.searchWord);
        },

        encodeSearchWord() {
          return encodeURIComponent(this.searchWord);
        }
      },

      methods: {
        onFocusInput() {
          this.onFocus = true;
        },

        onBlurInput() {
          this.onFocus = false;
        },

        onClickTypeMedia() {
          this.setSearchTypeMedia();

          if (this.productType == PRODUCT_TYPE.DRAMA) {
            this.setActionByUrl(url.SEARCH_DRAMAS);
          } else if (this.productType == PRODUCT_TYPE.ANIME) {
            this.setActionByUrl(url.SEARCH_ANIMES);
          } else {
            this.setActionByUrl(url.SEARCH_MOVIES);
          }
        },

        onClickTypePeople() {
          this.setSearchTypePeople();

          if (this.productType == PRODUCT_TYPE.DRAMA) {
            this.setActionByUrl(url.SEARCH_DRAMA_PEOPLE);
          } else if (this.productType == PRODUCT_TYPE.ANIME) {
            this.setActionByUrl(url.SEARCH_ANIME_PEOPLE);
          } else {
            this.setActionByUrl(url.SEARCH_PEOPLE);
          }
        },

        onClickTypeUser() {
          this.setSearchTypeUser();

          if (this.productType == PRODUCT_TYPE.DRAMA) {
            this.setActionByUrl(url.SEARCH_DRAMA_USERS);
          } else if (this.productType == PRODUCT_TYPE.ANIME) {
            this.setActionByUrl(url.SEARCH_ANIME_USERS);
          } else {
            this.setActionByUrl(url.SEARCH_USERS);
          }
        },

        setActionByUrl(actionUrl) {
          this.$refs.jsSearchFormChildRef.parentElement.action = actionUrl;
        },

        getPlaceHolder() {
          switch (this.productType) {
            case PRODUCT_TYPE.ANIME:
              return 'アニメ・キャスト・ユーザー検索';
            case PRODUCT_TYPE.DRAMA:
              return 'ドラマ・キャスト・ユーザー検索';
            default:
              return '映画・キャスト・ユーザー検索';
          }
        },

        checkSearchLocation() {
          const pathName = window.location.pathname;

          // productType判定
          if (pathName.includes(PRODUCT_TYPE.DRAMA)) {
            this.productType = PRODUCT_TYPE.DRAMA;
          } else if (pathName.includes(PRODUCT_TYPE.ANIME)) {
            this.productType = PRODUCT_TYPE.ANIME;
          } else {
            this.productType = PRODUCT_TYPE.MOVIE;
          }

          // searchType判定
          if (/search\/movies/.test(pathName)) {
            // 映画検索
            this.onClickTypeMedia();
          } else if (/search\/people/.test(pathName)) {
            // 映画キャスト検索
            this.onClickTypePeople();
          } else if (/search\/users/.test(pathName)) {
            // 映画ユーザー検索
            this.onClickTypeUser();
          } else if (/search\/dramas/.test(pathName)) {
            // ドラマ検索
            this.onClickTypeMedia();
          } else if (/search\/people\/dramas/.test(pathName)) {
            // ドラマキャスト検索
            this.onClickTypePeople();
          } else if (/search\/users\/dramas/.test(pathName)) {
            // ドラマユーザー検索
            this.onClickTypeUser();
          } else if (/search\/animes/.test(pathName)) {
            // アニメ検索
            this.onClickTypeMedia();
          } else if (/search\/people\/animes/.test(pathName)) {
            // アニメキャスト検索
            this.onClickTypePeople();
          } else if (/search\/users\/animes/.test(pathName)) {
            // アニメユーザー検索
            this.onClickTypeUser();
          } else {
            //
          }
        },

        setSearchTypeMedia() {
          this.selectType = 0;
        },

        setSearchTypePeople() {
          this.selectType = 1;
        },

        setSearchTypeUser() {
          this.selectType = 2;
        }
      }
    });

    app.use(bugsnagVue).mount('#js-search-form');
  }
});
