import { createApp } from 'vue';
import OnlineMovieAllowedEnv from './../modules/OnlineMovieAllowedEnv';
import OnlineMoviePlayer from '../components/OnlineMoviePlayer';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-online-movie-sample')) {
    const app = createApp({
      mixins: [OnlineMovieAllowedEnv],

      data() {
        return {
          autoplay: false,
          isSample: true
        };
      },

      components: {
        OnlineMoviePlayer
      }
    });

    app.use(bugsnagVue).mount('#js-online-movie-sample');
  }
});
