import Base from './Base';
import { transformRequest } from '../utils/TextFormat';

export default class Drama extends Base {
  constructor() {
    super();
  }

  // Mark Mark投稿
  saveMark(dramaSeriesId, dramaSeasonId, params) {
    return this.client.post(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks`, params, {
      transformRequest: [transformRequest]
    });
  }

  // Mark Mark詳細取得
  fetchMark(dramaSeriesId, dramaSeasonId, markId, params) {
    return this.client.get(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks/${markId}`, {
      params: params
    });
  }

  // Mark Mark更新
  updateMark(dramaSeriesId, dramaSeasonId, markId, params) {
    return this.client.patch(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks/${markId}`, params, {
      transformRequest: [transformRequest]
    });
  }

  // Mark Mark削除
  deleteMark(dramaSeriesId, dramaSeasonId, markId, params) {
    return this.client.delete(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks/${markId}`, params);
  }

  saveClip(dramaSeriesId, dramaSeasonId, params) {
    return this.client.post(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/clips`, params);
  }

  deleteClip(dramaSeriesId, dramaSeasonId, clipId, params) {
    return this.client.delete(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/clips/${clipId}`, params);
  }

  // TODO: API実装後対応
  // MarkLike Markにいいね！ 登録
  // saveMarkLike(dramaSeriesId, dramaSeasonId, markId, params) {
  //   return this.client.post(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks/${markId}/likes`, params);
  // }

  // TODO: API実装後対応
  // MarkLike Markにつけたいいね！ 解除
  // deleteMarkLike(dramaSeriesId, dramaSeasonId, markId, markLikeId, params) {
  //   return this.client.delete(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks/${markId}/likes/${markLikeId}`, params);
  // }

  // TODO: API実装後対応
  // MarkComment コメントを投稿
  // saveMarkComment(dramaSeriesId, dramaSeasonId, markId, params) {
  //   return this.client.post(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks/${markId}/comments`, params);
  // }

  // MarkComment コメントを取得
  fetchMarkComment(dramaSeriesId, dramaSeasonId, markId, params) {
    return this.client.get(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks/${markId}/comments`, {
      params: params
    });
  }

  // TODO: API実装後対応
  // MarkComment コメントを削除
  // deleteMarkComment(dramaSeriesId, dramaSeasonId, markCommentId, params) {
  //   return this.client.delete(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks/${markId}/comments/${markCommentId}`, params);
  // }

  // ユーザ Markにいいね！したユーザー一覧
  fetchMarkLikeUsers(dramaSeriesId, dramaSeasonId, markId, params) {
    return this.client.get(`/dramas/series/${dramaSeriesId}/seasons/${dramaSeasonId}/marks/${markId}/likes/users`, {
      params: params
    });
  }
}
