import { createApp, onMounted } from 'vue';
import { useClip } from '../../composables/useClip';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { PRODUCT_TYPE } from '../../constants/MediaType';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-btn-anime-season-clip'), (AnimeSeasonClipBtn) => {
    const app = createApp({
      setup() {
        const { clip, viewingClipCount, onHookMounted, onClickClipButton } = useClip(PRODUCT_TYPE.ANIME);

        onMounted(() => {
          onHookMounted(AnimeSeasonClipBtn.dataset.animeSeasonClip);
        });

        return {
          clip,
          viewingClipCount,
          onClickClipButton
        };
      }
    });

    app.use(bugsnagVue).mount(AnimeSeasonClipBtn);
  });
});
