import { PRODUCT_TYPE } from '../constants/MediaType';

export const useCategory = () => {
  const path = window.location.pathname;

  if (path.includes(PRODUCT_TYPE.DRAMA)) {
    return PRODUCT_TYPE.DRAMA;
  } else if (path.includes(PRODUCT_TYPE.ANIME)) {
    return PRODUCT_TYPE.ANIME;
  } else {
    return PRODUCT_TYPE.MOVIE;
  }
};
