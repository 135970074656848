export const SEARCH_MOVIES = '/search/movies?q=';
export const SEARCH_DRAMAS = '/search/dramas?q=';
export const SEARCH_ANIMES = '/search/animes?q=';
export const SEARCH_PEOPLE = '/search/people?q=';
export const SEARCH_DRAMA_PEOPLE = '/search/people/dramas?q=';
export const SEARCH_ANIME_PEOPLE = '/search/people/animes?q=';
export const SEARCH_USERS = '/search/users?q=';
export const SEARCH_DRAMA_USERS = '/search/users/dramas?q=';
export const SEARCH_ANIME_USERS = '/search/users/animes?q=';
export const OMNIAUTH_FACEBOOK = '/omniauth/facebook';
export const OMNIAUTH_TWITTER = '/omniauth/twitter';
export const OMNIAUTH_APPLE = '/omniauth/apple';
export const OMNIAUTH_TWITTER_WINDOW = OMNIAUTH_TWITTER + '?window=share';
export const UPDATE_PASSWORD = '/my/password';
