import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

const ignoreErrors = {
  TypeError: [
    "null is not an object (evaluating 'window.parent.document.getElementById",
    "Cannot set properties of null (setting 'height')",
    "null is not an object (evaluating 't.getBoundingClientRect')",
    'window.parent.document.getElementById(...) is null',
    "null is not an object (evaluating 'r.id')",
    "undefined is not an object (evaluating '__gCrWeb.edgeTranslate.detectPageState')"
  ],
  InvalidError: ['unhandledrejection handler received a non-error.'],
  ReferenceError: ["Can't find variable: _AutofillCallbackHandler"],
  'window.onerror': [
    'ResizeObserver loop completed with undelivered notifications.',
    'ResizeObserver loop limit exceeded'
  ],
  Error: ['ResizeObserver loop completed with undelivered notifications.']
};

Bugsnag.start({
  apiKey: 'c0e892524839e0838c99f348bd2f66b8',
  plugins: [new BugsnagPluginVue()],
  logger: null,
  releaseStage: process.env.NODE_ENV,
  onError: function (event) {
    switch (event.errors?.[0]?.errorClass) {
      case 'window.onerror':
      case 'TypeError':
      case 'InvalidError':
      case 'ReferenceError':
      case 'Error':
        for (let ignoreError of ignoreErrors[event.errors[0].errorClass]) {
          if (event.errors[0].errorMessage?.includes(ignoreError)) {
            return false;
          }
        }
        return true;
      default:
        return true;
    }
  }
});

export const bugsnagVue = Bugsnag.getPlugin('vue');
