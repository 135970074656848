export const PRODUCT_TYPE = {
  MOVIE: 'movie',
  DRAMA: 'drama',
  ANIME: 'anime'
};

export const SEARCH_TYPE = {
  MEDIA: 0,
  PEOPLE: 1,
  USER: 2
};
