import { createApp, onMounted } from 'vue';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { useClip } from '../../composables/useClip';
import { PRODUCT_TYPE } from '../../constants/MediaType';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-btn-clip'), (clipBtn) => {
    const app = createApp({
      setup() {
        const { clip, onHookMounted, viewingClipCount, onClickClipButton } = useClip(PRODUCT_TYPE.MOVIE);

        onMounted(() => {
          onHookMounted(clipBtn.dataset.clip);
        });

        return {
          clip,
          viewingClipCount,
          onClickClipButton
        };
      }
    });

    app.use(bugsnagVue).mount(clipBtn);
  });
});
