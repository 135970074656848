import { createApp, onMounted } from 'vue';
import { useClip } from '../../composables/useClip';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { PRODUCT_TYPE } from '../../constants/MediaType';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-btn-drama-season-clip'), (DramaSeasonClipBtn) => {
    const app = createApp({
      setup() {
        const { clip, onHookMounted, viewingClipCount, onClickClipButton } = useClip(PRODUCT_TYPE.DRAMA);

        onMounted(() => {
          onHookMounted(DramaSeasonClipBtn.dataset.dramaSeasonClip);
        });

        return {
          clip,
          viewingClipCount,
          onClickClipButton
        };
      }
    });

    app.use(bugsnagVue).mount(DramaSeasonClipBtn);
  });
});
