import { createApp } from 'vue';
import eventHub from './../../common/modules/EventHub';
import MarkLikeUserList from './../components/MarkLikeUserList';
import ErrorModal from './../components/ErrorModal';
import errorNotification from './../../utils/ErrorNotification';
import MovieAPI from './../../api_clients/Movie';
import { bugsnagVue } from '../../utils/BugsnagInit';

const movieAPI = new MovieAPI();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-mark-like-user')) {
    const app = createApp({
      data() {
        return {
          movieId: '',
          markId: '',
          likeId: '',
          likeCount: 0,
          isUserSignedIn: false,
          isActive: false,
          users: [],
          nextPage: 1,
          isApiProcessing: false
        };
      },

      created: function () {
        eventHub.$on('fetch-mark-like-users', this.fetchMarkLikeUsers);
        eventHub.$on('save-mark-like', this.saveMarkLike);
        eventHub.$on('delete-mark-like', this.deleteMarkLike);
      },

      mounted: function () {
        const { dataset } = this.$el.parentElement;

        this.movieId = dataset.movieId;
        this.markId = dataset.markId;
        this.likeId = dataset.likeId;
        this.likeCount = dataset.likeCount;
        this.isUserSignedIn = dataset.isUserSignedIn;
        this.isActive = dataset.isMarked;

        this.fetchMarkLikeUsers();
      },

      beforeUnmount: function () {
        eventHub.$off('fetch-mark-like-users', this.fetchMarkLikeUsers);
        eventHub.$off('save-mark-like', this.saveMarkLike);
        eventHub.$off('delete-mark-like', this.deleteMarkLike);
      },

      methods: {
        fetchMarkLikeUsers: function () {
          const params = {
            page: this.nextPage
          };

          movieAPI
            .fetchMarkLikeUsers(this.movieId, this.markId, params)
            .then((response) => {
              this.setMarkLikeUsersData(response.data);
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        saveMarkLike: function () {
          this.isApiProcessing = true;
          movieAPI
            .saveMarkLike(this.movieId, this.markId)
            .then((response) => {
              this.setMarkLikeData(response.data);
              this.addMarkLikeUserData(response.data.markLike);
              this.isActive = true;
              this.isApiProcessing = false;
            })
            .catch((response) => {
              this.isApiProcessing = false;
              this.$refs.errorModal.showIfDetail(response);
            });
        },

        deleteMarkLike: function () {
          this.isApiProcessing = true;
          movieAPI
            .deleteMarkLike(this.movieId, this.markId, this.likeId)
            .then((response) => {
              this.setMarkLikeData(response.data);
              this.removeMarkLikeUserData(response.data.markLike);
              this.isActive = false;
              this.isApiProcessing = false;
            })
            .catch((response) => {
              this.isApiProcessing = false;
              errorNotification(response);
            });
        },

        setMarkLikeUsersData: function (data) {
          this.users = this.users.concat(data.users);
          this.nextPage = data.nextPage;
        },

        setMarkLikeData: function (data) {
          this.likeId = data.markLike.id;
          this.likeCount = data.markLike.count;
        },

        addMarkLikeUserData: function (data) {
          this.users.unshift(data.user);
        },

        removeMarkLikeUserData: function (data) {
          this.users.some((user, i) => {
            if (user.id === data.user.id) this.users.splice(i, 1);
          });
        }
      },

      components: {
        MarkLikeUserList,
        ErrorModal
      }
    });

    app.use(bugsnagVue).mount('#js-mark-like-user');
  }
});
