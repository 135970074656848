import keycode from 'keycode';
import SnsAPI from '../api_clients/SNS';
import hyphenNumber from '../utils/HyphenNumber';
import { computed } from 'vue';

const snsAPI = new SnsAPI();

export const emitEvents = ['close-editor', 'change-spoiler', 'save-mark', 'delete-mark'];

export const markEditorProps = {
  isShowTwitterButton: {
    type: Boolean,
    default: true
  },
  isActive: {
    type: Boolean,
    required: true
  },
  isApiProcessing: {
    type: Boolean,
    required: true
  },
  mark: {
    type: Object,
    required: true
  },
  show: {
    type: Boolean,
    required: true
  },
  social: {
    type: Object,
    default: null
  }
};

export const useMarkEditor = (props, { emit }) => {
  const options = {
    tooltip: 'none',
    min: 0.0,
    max: 5.0,
    interval: 0.1,
    duration: 0.2
  };

  const removeDuplicatedTagValue = (array) => {
    // 配列内の重複している値を削除
    return [...new Set(array)];
  };

  const tagsArrayToString = (tagsArray) => {
    let tagsString = '';

    const _tagsArray = removeDuplicatedTagValue(tagsArray);

    _tagsArray.forEach((value) => {
      tagsString += `#${value.replace(/[\s ]+/g, '')} `;
    });

    return tagsString.slice(0, tagsString.length - 1);
  };

  const tagsStringToArray = (tagsString) => {
    const tagsArray = tagsString.replace(/[\s ]+/g, '').split('#');

    return tagsArray.filter((value) => {
      return value.replace(/[\s ]+/g, '') !== '';
    });
  };

  const viewScore = computed(() => hyphenNumber(props.mark.score, true));
  const tagsString = computed(() => tagsArrayToString(props.mark.tags));
  const reviewPlaceholder = computed(() =>
    props.mark.isSpoiler ? 'レビューを入力' : 'レビュー無記入でも投稿できます。'
  );

  const onChangeTextarea = (e) => {
    props.mark.tags = tagsStringToArray(e.target.value);
  };

  const onClickClose = () => {
    emit('close-editor');
  };

  const onClickSpoiler = () => {
    emit('change-spoiler');
  };

  const onClickShareTwitter = () => {
    if (props.social.twitter) {
      emit('change-share-twitter');
    } else {
      snsAPI.createTwitterTokenWithPopup();
    }
  };

  const onClickSave = () => {
    props.mark.tags = tagsStringToArray(tagsArrayToString(props.mark.tags));
    emit('save-mark');
  };

  const onClickDelete = () => {
    emit('delete-mark');
  };

  const keyDownTags = (e) => {
    // Enterの入力は許可しない
    if (keycode(e) === 'enter') {
      e.preventDefault();
      return;
    }

    const targetElm = e.target;

    // 未入力の場合はタグ入力を促す「#」を挿入
    if (targetElm.value === '') {
      targetElm.value = '#';
      return;
    }

    // Spaceが入力されたら次のタグ入力を促す「#」を挿入
    if (keycode(e) === 'space') {
      e.preventDefault();
      const oldVal = targetElm.value;
      const pos = targetElm.selectionStart;
      const leftVal = oldVal.substr(0, pos);
      const rightVal = oldVal.substr(pos, Array.from(oldVal).length);

      if (rightVal.substr(0, 1) === '#') {
        targetElm.value = leftVal + ' # ' + rightVal;
      } else {
        targetElm.value = leftVal + ' #' + rightVal;
      }

      targetElm.selectionStart = targetElm.selectionEnd = pos + 2;

      return;
    }
  };

  const checkScore = (e) => {
    if (props.mark.score < 1.0) {
      setTimeout(() => {
        props.mark.score = 0.0;
      }, 100);
    }
  };

  const changeScore = (value) => {
    props.mark.score = parseFloat(value);
  };

  return {
    options,
    viewScore,
    tagsString,
    reviewPlaceholder,
    onChangeTextarea,
    onClickClose,
    onClickSpoiler,
    onClickSave,
    onClickDelete,
    keyDownTags,
    checkScore,
    changeScore,
    onClickShareTwitter
  };
};
