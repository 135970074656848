import { createApp } from 'vue';
import eventHub from './../../common/modules/EventHub';
import AnimeMarkLikeUserList from './../components/AnimeMarkLikeUserList';
import errorNotification from './../../utils/ErrorNotification';
import AnimeAPI from './../../api_clients/Anime';
import { bugsnagVue } from '../../utils/BugsnagInit';

const animeAPI = new AnimeAPI();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-anime-mark-like-user')) {
    const jsAnimeMarkLikeUser = document.getElementById('js-anime-mark-like-user');
    const app = createApp({
      data() {
        return {
          animeSeriesId: '',
          animeSeasonId: '',
          markId: '',
          likeId: '',
          likeCount: '',
          isUserSignedIn: false,
          isMarked: false,
          users: [],
          nextPage: false
        };
      },

      created: function () {
        eventHub.$on('fetch-mark-like-users', this.fetchMarkLikeUsers);
      },

      mounted: function () {
        const { dataset } = this.$el.parentElement;

        this.animeSeriesId = dataset.animeSeriesId;
        this.animeSeasonId = dataset.animeSeasonId;
        this.markId = dataset.markId;
        this.likeId = dataset.likeId;
        this.likeCount = dataset.likeCount;
        this.isUserSignedIn = dataset.isUserSignedIn;
        this.isMarked = dataset.isMarked;

        this.fetchMarkLikeUsers();
      },

      beforeUnmount: function () {
        eventHub.$off('fetch-mark-like-users', this.fetchMarkLikeUsers);
      },

      methods: {
        toBoolean: function (val) {
          return val === 'true';
        },

        fetchMarkLikeUsers: function () {
          const params = {
            page: this.nextPage
          };

          animeAPI
            .fetchMarkLikeUsers(this.animeSeriesId, this.animeSeasonId, this.markId, params)
            .then((response) => {
              this.setMarkLikeUsersData(response.data);
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        setMarkLikeUsersData: function (data) {
          this.users = this.users.concat(data.users);
          this.nextPage = data.nextPage;
        }
      },

      components: {
        AnimeMarkLikeUserList
      }
    });

    app.use(bugsnagVue).mount(jsAnimeMarkLikeUser);
  }
});
